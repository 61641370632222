import styled from "styled-components";
import {
  Rem,
  BP,
} from "../../../commons/Theme";
import { motion } from "framer-motion";

export const StyledBeeSectionTitle = styled(motion.h1)`
  font-family: 'Vollkorn';
  text-align: center;
  font-size: ${Rem(40)};
  @media (${BP.tablet}) {
    padding-bottom: ${Rem(20)};
  }
`;

export const StyledBeeSectionSubTitle = styled(motion.h2)`
  text-align: center;
  font-size: ${Rem(25)};
  padding: 0 2rem;
  @media (${BP.tablet}) {
    padding: ${Rem(40)} 0;
    font-size: ${Rem(40)};
  }
`;

export const BeeWrapper = styled(motion.div)`
  padding: ${Rem(80)} 0;
  position: relative;
  @media (${BP.tablet}) {
    padding: ${Rem(140)} 0;
  }
`;

export const StyledTwoColWrapper = styled(motion.div)`
`;
