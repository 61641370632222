import React, { useRef, useEffect } from "react";
import SectionTwoCol from "../../../components/SectionTwoCol/SectionTwoCol";
import {
  StyledBeeSectionTitle,
  StyledBeeSectionSubTitle,
  BeeWrapper,
  StyledTwoColWrapper,
} from "./style.jsx";
import { useAnimation } from "framer-motion";
import { fadeOnBottom } from "../animations";
import { useInView } from "react-intersection-observer"; 
import loadable from "@loadable/component";
import useCheckMobileScreen from "../../../hooks/useCheckMobileScreen";
import { BPSizes } from "../../../commons/Theme";

const ExpandingLine = loadable(props => import("../ExpandingLine/ExpandingLine"), {
  ssr: false,
});

export default ({ content, video }) => {
  const containerRef = useRef();
  const [ref, inView] = useInView({ threshold: 0.1 });
  const startAnimate = useAnimation();
  const getParentElement = () => {
    return containerRef;
  };
  useEffect(() => {
    if (inView ) {
      startAnimate.start("onscreen");
    }
  }, [inView]);
  const isMobile = useCheckMobileScreen();
  const isDeskSmallScreen = useCheckMobileScreen(BPSizes.large);

  return (
    <div ref={ref}>
      <BeeWrapper 
        ref={containerRef} 
        initial="offscreen"
        // whileInView="onscreen"
        animate={startAnimate}
        viewport={{ once: true, amount: 0.8 }}
      >
        <ExpandingLine
          parentElement={getParentElement}
          topDot="outlined"
          bottomDot="outlined"
          height="144px"
          top={isDeskSmallScreen ? "-45px" : "-55px"}
          offset={["-100vh", "-90vh"]}
        />
        <StyledBeeSectionTitle
          dangerouslySetInnerHTML={{ __html: content.title }}
          variants={fadeOnBottom}
        />
        <StyledTwoColWrapper>
          <SectionTwoCol
            id="beebox"
            ul={content.containsList.map((e) => {
              return { text: e };
            })}
            video={video}
            originalsMobile={isMobile ? true : false}
            noPadding
          />
        </StyledTwoColWrapper>
        <StyledBeeSectionSubTitle
          dangerouslySetInnerHTML={{ __html: content.paragraph }}
          variants={fadeOnBottom}
        />
      </BeeWrapper>
    </div>
  );
};
