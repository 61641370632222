import { useEffect, useState } from "react";

const useCheckMobileScreen = (widthToCheck = 768) => {
  // const [width, setWidth] = useState(
  //   typeof window !== "undefined" ? window.innerWidth : undefined,
  // );
  const [width, setWidth] = useState(undefined);
  
  useEffect(() => {
    const handleWindowSizeChange = () => {
      setWidth(typeof window !== "undefined" ? window.innerWidth : undefined);
    };
    
    handleWindowSizeChange();
    if (typeof window !== "undefined") {
      window.addEventListener("resize", handleWindowSizeChange);
    }

    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("resize", handleWindowSizeChange);
      }
    };
  }, []);

  return width <= widthToCheck;
};

export default useCheckMobileScreen;
